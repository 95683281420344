import React, { Component } from "react";
import { Map, Marker, Popup, Tooltip, TileLayer } from "react-leaflet";
import { CardTitle, Paragraph, Small } from "../components/text";

import "./Map.sass";

// const MapComponent = ReactMapboxGl({
//   accessToken: "pk.eyJ1Ijoib2xsZXN2IiwiYSI6IllQdDFvcEkifQ.Mjzq5Vc6iUwd2d6KnkM26A",
//   scrollZoom: false
// })

const find_location_icon = require("../img/find_location_icon.png");

const openStreetMapUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

let latLngBounds;
export default class MapLala extends Component {
  state = {
    latitude: 59.3260674,
    longitude: 18.0696948,
    zoom: 10,
    loaded: false,
    tilesUrl: openStreetMapUrl,
    geolocation_available: false,
  };

  componentDidMount = () => {
    const Leaflet = require("leaflet");
    latLngBounds = Leaflet.latLngBounds;
    this.setState({
      loaded: true,
      icon: new Leaflet.Icon({
        iconUrl: require("./marker.png"),
        shadowUrl: require("./marker_shadow.png"),
        iconSize: [30, 44], // size of the icon
        shadowSize: [32.5, 45], // size of the shadow
        iconAnchor: [15, 44], // point of the icon which will correspond to marker's location
        shadowAnchor: [0, 45], // the same for the shadow
        // popupAnchor:  [-3, -76]// point from which the popup should open relative to the iconAnchor
      }),
    });
    if ("geolocation" in navigator) {
      this.setState({ geolocation_available: true });
    } else {
      /* geolocation IS NOT available */
    }
  };

  getPosition = () => {
    if (!navigator.geolocation) {
      /* geolocation IS NOT available */
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        this.setState({
          latitude,
          longitude,
          zoom: 14,
        });
      },
      console.error,
      { maximumAge: 5000 }
    );
  };

  getBounds = () => {
    const bounds = latLngBounds([]);
    this.props.locations.forEach((data) => {
      bounds.extend([data.latitude, data.longitude]);
    });
    return bounds;
  };

  render() {
    const {
      geolocation_available,
      icon,
      latitude,
      longitude,
      loaded,
      sites,
      tilesUrl,
      zoom,
    } = this.state;

    const { locations } = this.props;

    if (typeof window === "undefined") {
      return null;
    }

    return (
      <div className="map-container">
        <Map
          center={[latitude, longitude]}
          zoom={zoom}
          style={{ height: "75vh" }}
          scrollWheelZoom={false}
          boundsOptions={{ padding: [50, 50] }}
          {...{
            bounds: loaded ? this.getBounds() : null,
          }}
        >
          <TileLayer
            url={tilesUrl}
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {locations &&
            locations.map((location) => (
              <Marker
                key={location.address}
                position={[location.latitude, location.longitude]}
                icon={icon}
              >
                <Popup closeButton={false}>
                  <span>
                    <CardTitle>{location.title}</CardTitle>
                    <Paragraph>{location.address}</Paragraph>
                    <Paragraph>
                      {location.post_code + " " + location.city}
                    </Paragraph>
                    <Paragraph>{location.country}</Paragraph>
                  </span>
                </Popup>
              </Marker>
            ))}
        </Map>
        {geolocation_available && (
          <div onClick={this.getPosition} className="find-location">
            <Small>Find your location</Small>
            <img src={find_location_icon} />
          </div>
        )}
      </div>
    );
  }
}
