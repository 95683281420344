import React, { Component } from "react";
import { graphql } from "gatsby";
import Page from "../components/page";
import Section from "../components/section";
import FullWidthImage from "../components/FullWidthImage";
import LastestBlogPosts from "../components/LastestBlogPosts";
import Features from "../components/Features";
import Departments from "../components/departments";
import Partners from "../components/partners";
import Map from "../components/Map";
// import Footer from "../components/footer";
import { SubTitle } from "../components/text";

import logo from "../img/logo_hero_white.svg";

const blog_posts_to_show = 3;

export class HomePageTemplate extends Component {
  render() {
    const {
      blog_posts,
      image,
      title,
      subtitle,
      servicesData,
      services,
      newsData,
      offices,
      partners,
      partnersData,
    } = this.props;

    return (
      <Page noBottomPadding>
        <FullWidthImage image={image} height="70vh" parallax={70} dim={0.4}>
          <img src={logo} alt="Wesmans" />
          <SubTitle bright marginTop>
            {subtitle}
          </SubTitle>
        </FullWidthImage>
        <Departments
          services={services}
          title={servicesData.title}
          buttonText={servicesData.buttonText}
          ingress={servicesData.ingress}
        />
        <LastestBlogPosts
          data={blog_posts}
          title={newsData.title}
          buttonText={newsData.buttonText}
          ingress={newsData.ingress}
        />
        <Partners
          partners={partners}
          title={partnersData.title}
          ingress={partnersData.ingress}
        />
        <Map locations={offices} />
      </Page>
    );
  }
}

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { edges } = data.allMarkdownRemark;
  const blog_posts = edges
    .filter((edge) => edge.node.frontmatter.templateKey === "blog-post")
    .map((edge) => ({
      ...edge.node.frontmatter,
      id: edge.node.fields.slug,
      excerpt: edge.node.excerpt,
    }))
    .slice(0, blog_posts_to_show);

  const services = edges
    .filter((edge) => edge.node.frontmatter.templateKey === "service")
    .map((edge) => ({
      ...edge.node.frontmatter,
      id: edge.node.fields.slug,
    }));

  const offices = edges
    .filter((edge) => edge.node.frontmatter.templateKey === "office")
    .map((edge) => ({
      ...edge.node.frontmatter,
      id: edge.node.fields.slug,
    }));

  const partners = edges
    .filter((edge) => edge.node.frontmatter.templateKey === "partner")
    .map((edge) => ({
      ...edge.node.frontmatter,
      id: edge.node.fields.slug,
    }));

  // console.log("partners", partners);

  return (
    <HomePageTemplate
      image={frontmatter.image}
      title={frontmatter.title}
      subtitle={frontmatter.subtitle}
      servicesData={frontmatter.services}
      services={services}
      blog_posts={blog_posts}
      newsData={frontmatter.news}
      offices={offices}
      partners={partners}
      partnersData={frontmatter.partners}
    />
  );
};

export const homePageQuery = graphql`
  query homePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 64) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        services {
          title
          buttonText
          ingress
        }
        news {
          title
          buttonText
          ingress
        }
        partners {
          title
          ingress
        }
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            description
            templateKey
            latitude
            longitude
            title
            address
            post_code
            city
            country
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
