import React, { useMemo } from "react";
import { Element } from "react-scroll";
import sortBy from "lodash/sortBy";
import Section from "../components/section";
import { Columns, Column } from "../components/columns";
import { Paragraph } from "../components/text";

import "./partners.sass";

export default ({ buttonText, ingress, title, partners }) => {
  const sortedPartners = useMemo(
    () => (partners ? sortBy(partners, (partner) => partner.title) : []),
    [partners]
  );

  return (
    <Element name="partners">
      <Section title={title}>
        {ingress && ingress.length > 0 && (
          <Paragraph marginBottomLarge center>
            {ingress}
          </Paragraph>
        )}
        <Columns multiLine>
          {sortedPartners.map((entry) => (
            <Column key={entry.id} width="is-one-quarter">
              <div className="partner">
                <img
                  src={entry.image?.childImageSharp?.fluid.src}
                  alt={entry.title}
                />
              </div>
            </Column>
          ))}
        </Columns>
      </Section>
    </Element>
  );
};
