import React from "react";
import { Element } from "react-scroll";
import Section from "../components/section";
import { Columns, Column } from "../components/columns";
import { LinkButton } from "./button/button";
import {
  CardTitle,
  Title,
  Italic,
  Paragraph,
  SectionTitle,
  SubTitle,
} from "../components/text";

import "./departments.sass";

export default ({ buttonText, ingress, title, services }) => (
  <Element name="services">
    <Section title={title} diffBg>
      {ingress && ingress.length > 0 && (
        <Paragraph marginBottomLarge center>
          {ingress}
        </Paragraph>
      )}
      <Columns multiLine>
        {services &&
          services.map((entry) => (
            <Column key={entry.id}>
              <div className="department">
                <div className="department-image-container">
                  <div
                    className="department-image"
                    style={{
                      backgroundImage: `url(${entry.image?.childImageSharp?.fluid.src})`,
                    }}
                  ></div>
                </div>
                <div className="department-info">
                  <div>
                    <CardTitle center marginBottom>
                      {entry.title}
                    </CardTitle>
                    <Paragraph center marginBottom>
                      {entry.description}
                    </Paragraph>
                  </div>
                  <LinkButton
                    to={entry.id}
                    title={buttonText ? buttonText : "Read more"}
                    center
                    marginBottom
                  />
                </div>
              </div>
            </Column>
          ))}
      </Columns>
    </Section>
  </Element>
);

// <div
//   className="news-image"
//   style={{
//     backgroundImage: `url(${ entry.image })`
//   }}
// >
// </div>
// <img src={ entry.image } />
