import React from "react";
import { Link } from "gatsby";
import { Element } from "react-scroll";
import FullWidthImage from "../components/FullWidthImage";
import Section from "../components/section";
import { LinkButton } from "./button/button";
import NewsItem from "../components/news_item";
import { Columns, Column } from "../components/columns";
import { Paragraph } from "../components/text";

import "./LatestBlogPosts.sass";

export default ({ data, title, buttonText, ingress }) => (
  <Element name="news">
    <Section title={title} diffBg>
      {ingress && ingress.length > 0 && (
        <Paragraph marginBottomLarge center>
          {ingress}
        </Paragraph>
      )}
      <LinkButton to="/blog" title="View all" center marginBottomLarge />
      {data &&
        data.map((post) => (
          <NewsItem
            key={post.id}
            image={post.image}
            title={post.title}
            date={post.date}
            excerpt={post.excerpt}
            id={post.id}
            buttonText={buttonText}
          />
        ))}
    </Section>
  </Element>
);
